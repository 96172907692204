import { ResolveFn } from '@angular/router';
import { PageDataService } from '../services/crowdbuilding-cms/services/data/module/page.data.service';
import { inject } from '@angular/core';

export const PageTitleResolver: ResolveFn<string> = () => {
  const page = inject(PageDataService).peekItem();
  if (! page) {
    return '';
  }

  return page.meta_tags.title || '';
};
